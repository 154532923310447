//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$COLOR_BLUE_1: #13262f;
$COLOR_BLUE_2: #2b3c44;

$COLOR_RED_1: #ec6c6e;

$COLOR_WHITE: #fff;

$COLOR_GRAY_1: #b4b7bd;
$COLOR_GRAY_2: #464748;

$COLOR_BLACK_1: #000;
$COLOR_BLACK_2: #797979;

$FONT_SIZE_12: 12px;
$FONT_SIZE_14: 14px;
$FONT_SIZE_18: 18px;